import React, { createContext, useState } from "react";

export const UserContext = createContext();


// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
    const [isNewDataLead,setIsNewDataLead] = useState(false)
    const [isNewDataOrders,setIsNewDataOrders] = useState(false)
    const [urlAllsalesOrderBar,setUrlAllsalesOrderBar] = useState('')
    const [urlIndividualOrders,setUrlIndividualOrders] = useState('')
    const [urlEachSalesBarChart,setUrlEachSalesBarChart] = useState('')
    const [alertAction,setAlertAction] = useState({message:'',open:false,type:''});
  
    return ( 
      <UserContext.Provider
        value={{
            user, setUser,
            alertAction,setAlertAction,
            isNewDataLead,setIsNewDataLead,
            isNewDataOrders,setIsNewDataOrders,
            urlAllsalesOrderBar,setUrlAllsalesOrderBar,
            urlIndividualOrders,setUrlIndividualOrders,
            urlEachSalesBarChart,setUrlEachSalesBarChart
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };