import React from "react";
import MaterialLayout from "./components/materialLayout";

const Routes = () => {

  return (
    <MaterialLayout />
  );
};

export default Routes;
