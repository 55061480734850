import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context';

import "./index.scss"

ReactDOM.render(
    <UserProvider>
      <Routes />
    </UserProvider>,
  document.getElementById('root')
);

reportWebVitals();
  