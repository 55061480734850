import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Button, CircularProgress, CssBaseline, Drawer } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import ReorderIcon from '@material-ui/icons/Reorder';
import { makeStyles } from "@material-ui/core/styles";
import "./index.scss"

const AllProducts = lazy(() => import('../../pages/all-product'))
const Login = lazy(() => import('../../pages/auth/login'))
const WebSiteHomePage = lazy(() => import('../../pages/website/home'))
const ProductNotify = lazy(() => import('../../pages/product-notify'))
const DataCleaningTrack = lazy(() => import('../../pages/data-cleaning-matrics'))
const Leads = lazy(() => import("../../pages/leads"));
const Allstaff = lazy(() => import("../../pages/all-staff"));
const EmployeeTimeTracking = lazy(() => import("../../pages/employeeTimeTracking"));
const IntransitInvoiceMerge = lazy(() => import("../../pages/intransitInvoiceMerge"));
const MessageLeads = lazy(() => import('../../pages/MessageLeads'));
const SalesTracking = lazy(() => import('../../pages/salesTracking'))
const InventoryList = lazy(() => import('../../pages/inventoryList'));
const ApprobalHistory = lazy(() => import('../../pages/approbalHistory'));
const IntransitInventory = lazy(() => import('../../pages/IntransitInventory'))
const ErrorLogs = lazy(() => import('../../pages/error-logs'));
// const Prescriptions = lazy(() => import('../../pages/prescriptions'));
// const PrescriptionSystem = lazy(() => import('../../pages/Prescription_System/PrescriptionSystem'));
const ListSideBarMenu = lazy(() => import('../../hoc/listSidebarMenu'));
const Inventory_move = lazy(() => import('../../components/inventory_move/index'))
const Homepage= lazy(() => import('../../pages/Homepage/Homepage'))
const Inventory_move_manage = lazy(() => import('../../pages/Inventory_move_manage'))
const dist_add = lazy(() => import('../../pages/Distributor'))
const Metatags= lazy(() => import('../../pages/Metatags'))
const Prescription= lazy(() => import('../../pages/Prescription_System/PrescriptionSystem'))
const Attendence= lazy(() => import('../../pages/Attendence/Attendence'))
const VerticalAttendence= lazy(() => import('../../pages/Attendence/VerticalAttendence.js'))
const TodayAttendence= lazy(() => import('../../pages/Attendence/TodayAttendence'))
const DateWiseAttendence= lazy(() => import('../../pages/Attendence/DateWiseAttendence'))
const ProductDescription= lazy(() => import('../../pages/Product_Description/ProductDescription'))
const CalenderWiseAttendence= lazy(() => import('../../pages/Attendence/CalenderWiseAttendence'))
const FinanceDashboard = lazy(()=>import("../../pages/FinanceDashboard/FinanceDashboard"))
let theme = createMuiTheme({
  typography: { 
    fontFamily: "Raleway, Arial",
  },
});

theme = responsiveFontSizes(theme);
const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    overflow:"scroll",
  },
  drawerPaper: {
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
      flexShrink: 0,
      background: '#081738',
      overflow:"scroll",
    },
  },
}));


const AppLayout = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Suspense fallback={<CircularProgress color="inherit" size={40} />
}>
            {
              window.location.pathname?.includes('dashboard') &&
              <Fragment>
                <Button onClick={handleOpen} style={{
                  background: '#058cf6',
                  position: 'fixed',
                  top: '3rem',
                  left: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}>
                  <ReorderIcon style={{ color: '#fff' }} />
                </Button>
                <Drawer
                  onClose={handleOpen}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="temporary"
                  open={open}
                  className="sidebar"
                  >
                  <ListSideBarMenu />
                </Drawer> 
              </Fragment>
            }
            <Route exact path="/dashboard/parchi_cleaner" component={dist_add} />
            <Route exact path="/dashboard/inventory/distributor-stock-add" component={dist_add} />
            <Route exact path="/dashboard/saya_homepage" component={Homepage} />
            <Route exact path="/dashboard/inventory-move" component={Inventory_move} />
            <Route exact path="/dashboard/inventory-move-manage" component={Inventory_move_manage} />
            <Route exact path="/dashboard/notification" component={MessageLeads} />
            <Route exact path="/dashboard/Staff/all-staff" component={Allstaff} /> 
            <Route exact path="/dashboard/Attendence" component={Attendence} /> 
            <Route exact path="/dashboard/Attendence_vertical" component={VerticalAttendence} /> 
            <Route exact path="/dashboard/Today_Attendence" component={TodayAttendence} /> 
            <Route exact path="/dashboard/DateWise" component={DateWiseAttendence} /> 
            <Route exact path="/dashboard/Staff/time-tracking" component={EmployeeTimeTracking} /> 
            <Route exact path="/dashboard/sales/sales-tracking" component={SalesTracking} />
            <Route exact path="/dashboard/sales/leads" component={Leads} />
            <Route exact path="/dashboard/inventory/all-product" component={AllProducts} />
            <Route exact path="/dashboard/inventory/data-cleaning-matrics" component={DataCleaningTrack} />
            <Route exact path="/dashboard/inventory/approval-request" component={InventoryList} />
            <Route exact path="/dashboard/inventory/approval-history" component={ApprobalHistory} />
            <Route exact path="/dashboard/inventory/error-logs" component={ErrorLogs} />
            <Route exact path='/dashboard/inventory/intransit-inventory' component={IntransitInventory} />
            <Route exact path='/dashboard/inventory/Intransit-invoice-Merge' component={IntransitInvoiceMerge} />
            <Route exact path='/dashboard/inventory/product-notify' component={ProductNotify} />
            <Route exact path='/dashboard/inventory/prescriptions' component={Prescription} />
            <Route exact path='/dashboard/website-admin/home' component={WebSiteHomePage} />
            <Route exact path='/dashboard/Metatags' component={Metatags} />
            <Route exact path='/dashboard/CalenderWise' component={CalenderWiseAttendence} />
            {/* <Route exact path='/dashboard/prescription_system' component={PrescriptionSystem} /> */}
            <Route exact path='/dashboard/product_description' component={ProductDescription} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            <Route exat path="/finance-dashboard" component={FinanceDashboard}/>
          </Suspense>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default AppLayout;
